
import { defineComponent, onMounted, ref, watch } from "vue"
import FilterBaseV2, { IEmitRequestFlow, IEmitGlobalFields } from "@/layout/header/components/FilterBaseV2.vue"
import { useLoaderStore } from "@/store/LoaderStore"
import { useFilterBaseV2Store } from "@/store/FilterBaseV2Store";
import { ElConfigProvider, ElDatePicker } from "element-plus"
import ptBr from "element-plus/lib/locale/lang/pt-br"

export interface IFilterStockSearchForm {
  oldest: boolean | null,
  movement_id: string | null,
  movement_type: string[] | null,
  responsible: string | null,
  startDate: string | null,
  endDate: string | null,
}

const MOVEMENT_LIST = [
  { value: 'input', label: 'Entrada' },
  { value: 'output', label: 'Saída' },
  { value: 'return', label: 'Estorno' },
]

const OLDEST_LIST = [
  { value: 0, label: "Mais recente" },
  { value: 1, label: "Mais antigo" },
]

export default defineComponent({
  name: "FilterStockListPage",
  components: {
    FilterBaseV2,
    ElConfigProvider,
    ElDatePicker
  },
  setup(props, { emit }) {
    /** Variables */
    const isActiveModal  = ref(false)
    const isCloseModal = ref(false)
    const loaderStore = useLoaderStore()
    const filterBaseV2Store = useFilterBaseV2Store()
    const rangeDate = ref<any>("")
    const searchForm = ref<IFilterStockSearchForm>({
      oldest: null,
      movement_id: null,
      movement_type: null,
      responsible: null,
      startDate: null,
      endDate: null,
    })

    /** Functions */
    function handleChangesGlobalFields(emit: IEmitGlobalFields) {
      // console.log("EmitGlobalFields: ", emit)
    }

    async function handleRequestFlow(emit: IEmitRequestFlow) {
      // console.log("EmitRequestFlow: ", emit)

      isCloseModal.value = false
      loaderStore.open()
      if(!emit.loading) {
        isCloseModal.value = true
        loaderStore.close()
      }
      if(emit.error)
        loaderStore.close()
    }

    /** Life Cycles */
    watch(() => rangeDate.value, () => {
      searchForm.value.startDate = (Array.isArray(rangeDate.value) && rangeDate.value.length > 1) ? rangeDate.value[0] : null
      searchForm.value.endDate = (Array.isArray(rangeDate.value) && rangeDate.value.length > 1) ? rangeDate.value[1] : null
    }, { deep: true })

    watch(() => filterBaseV2Store.pageID, () => {
      searchForm.value = filterBaseV2Store.getSearchFormCache<IFilterStockSearchForm>()
      rangeDate.value = (searchForm.value.startDate && searchForm.value.endDate) ? [searchForm.value.startDate, searchForm.value.endDate] : ""
    }, { deep: true })

    return {
      isActiveModal,
      isCloseModal,
      handleChangesGlobalFields,
      handleRequestFlow,
      searchForm,
      MOVEMENT_LIST,
      OLDEST_LIST,
      rangeDate,
      ptBr,
    }
  },
})
